<template>
    <div class="content">
        <div class="header" v-if="User.user_type!==5">
            <el-form ref="form" label-width="80px" style="float: right;">
                <el-form-item label="车场">
                    <el-select v-model="park_id" placeholder="请选择车场" @change="ParkDataChanged">
                      <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                    </el-select>
                  </el-form-item>
            </el-form>
        </div>
        <div class="main" style="overflow-y: scroll;height:calc(100vh - 150px);">
            <el-row v-for="(page, index) of pages" :key="index" :gutter="20" style="margin:10px;">
                <el-col :xs="24" :sm="12" :md="rowsCount===2?12:(rowsCount===3?8:6)" v-for="item of page" :key="item.gt_id" >
                    <el-card>
                        <div slot="header">
                          <span style="font-size: 20px;font-weight: 600;">{{item.gt_name}}</span>
                        </div>
                        <div :style="{height:scrollerHeight}" style="position:relative">
                            <img v-if="item.pic_data===''" src="../../assets/image/6.png" style="width: 100%;height: 100%;"/>
                            <img v-else :src="'data:image/png;base64,'+item.pic_data" style="width: 100%;height: 100%;"/>
                            <div style="position: absolute; bottom: 10px;left: 15px;">
                                <el-button type="primary" v-if="(item.gt_enabel&0x01)===0x01" @click='OptType(item.gt_id,0,0)'>抓拍</el-button>
                                <el-button type="warning"  v-if="(item.gt_enabel&0x04)===0x04" @click='OptType(item.gt_id,1,0)'>开闸</el-button>
                                <el-button :type="item.cq_value===1?'success':'danger'"  v-if="(item.gt_enabel&0x10)===0x10" @click='OptType(item.gt_id,2,item.cq_value)'>{{item.cq_value===1?'打开车队模式':'关闭车队模式'}}</el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import util from '../../util/util'


export default{
    data:function(){
        return{
            rowsCount:2,
            park_id:0,
            ParkDataList:[],
            GateDataList:[],
            User:null,
            
        }
    },
    computed: {
        pages () {
            const pages = []
            this.GateDataList.forEach((item, index) => {
                const page = Math.floor(index / this.rowsCount)
                if (!pages[page]) {
                pages[page] = []
                }
                pages[page].push(item)
            })
            return pages
        },
        scrollerHeight(){
            var height= window.screen.availHeight
            var h=''
            if(this.rowsCount===2){
                h=((height-50)/2)+'px'
            }
            if(this.rowsCount===3){
                h=((height-50)/3)+'px'
            }
            if(this.rowsCount===4){
                h=((height-50)/4)+'px'
            }
            return h
        }
    },
    created(){
        this.User=util.JesToken()
    },
    
    mounted(){
        this.getParkData()
        this.InitWs()
    },
    methods:{
        InitWs(){
            var conn = util.InitWs()
            conn.on('ReceiveMessage', data => {
                var item= JSON.parse(data)
                if(item.service_name==="outpark"||item.service_name==="inpark"){
                   item.data.forEach(a=>{
                       if(a.park_id===this.park_id){
                           var arr= this.GateDataList.filter(item=>item.gt_id===a.gt_id)
                            if(arr.length>0){
                                if(item.service_name==="outpark"&&arr[0].gt_type===1){
                                    arr[0].pic_data=a.out_picdata
                                }
                                if(item.service_name==="inpark"&&arr[0].gt_type===0){
                                    arr[0].pic_data=a.in_picdata
                                }
                            }
                       }
                   })
                }
                
            })
            conn.start().catch(err=>{
                console.error(err.toString())
            })
        },
        getParkData(){
            if(this.User.user_type===5){
                this.park_id=this.User.user_sp_id
                this.ParkDataChanged(this.park_id)
            }
            else{
                util.Get('parkmanage/getParkDataList',{pageNo:0,pageSize:0,name:'',ser_id:0,union_id:0}).then(res=>{
                    if(res.rpStatus===10000){
                        this.ParkDataList= res.list
                        if(res.list.length>0){
                            this.park_id=res.list[0].pk_id
                            this.ParkDataChanged(this.park_id)
                        }
                    }
                })
            }
            
        },
        ParkDataChanged(value){
            var sid=''
            var hid=1
            if(this.User.user_type!==5){
                var arr= this.ParkDataList.filter(a=>a.pk_id===value)
                sid=arr[0].pk_schemaId
                hid=arr[0].pk_host_id
            }
            util.Get('parkmonitor/getgatedetailData?sid='+sid+"&hid="+hid).then(res=>{
                 if(res.rpStatus===10000){
                    res.list.forEach(a=>{
                        a.pic_data=''
                        a.cq_value=1  //车队模式
                    })
                    this.GateDataList=res.list
                    this.rowsCount=2
                    if(this.GateDataList.length>4&&this.GateDataList.length<10){
                        this.rowsCount=3
                    }
                    if(this.GateDataList.length>9){
                        this.rowsCount=4
                    }
                 }
            })
        },

        OptType(gtateId,opttype,cq_value){
            util.Post('parkmonitor/buttonevent',{park_id:this.park_id,gate_id:gtateId,event_type:opttype,value:cq_value}).then(res=>{
                if(res.rpStatus===10001){
                    this.$message.error(res.rpMsg)
                    return
                }
                if(opttype===2){
                    var arr= this.GateDataList.filter(a=>a.gt_id===gtateId)
                     if(cq_value===1){
                        arr[0].cq_value=0
                     }
                     else{
                         arr[0].cq_value=1
                     }

                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .content{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .header{
        width: 100%;
        height: 40px;
    }
    .main{
        margin-top: 15px;
        width: 100%;
    }
    ::v-deep .el-card>.el-card__header{
        height: 40px;
        font-size: 15px;
        line-height: 10px;
        text-align: left
    }
</style>